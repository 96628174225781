export default [
  {
    path: '/orders/echange',
    name: 'echange-order',
    props: true,
    component: () => import('@/views/orders/Echange.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Echange colis',
      breadcrumb: [
        {
          text: 'Colis',
        },
        {
          text: 'Echange Colis',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
  {
    admin: true,
    path: '/orders/quick-edit',
    name: 'orders-quick-edit',
    props: true,
    component: () => import('@/views/orders/QuickUpdate.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modification Rapide',
      breadcrumb: [
        {
          text: 'chercher un colis',
        },
        {
          text: 'Modifier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/historique',
    name: 'order-hist',
    props: true,
    component: () => import('@/views/orders/Historique.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Historique de colis',
      breadcrumb: [
        {
          text: 'Colis',
        },
        {
          text: 'Historique Colis',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
  {
    path: '/orders/status',
    name: 'order-status',
    props: true,
    component: () => import('@/views/orders/Status.vue'),
    meta: {
      layout: 'vertical',
      requireLogin: true,
      pageTitle: 'Colis',
      breadcrumb: [
        {
          text: 'Réception central',
          active: true,
        },
      ],
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/orders/create',
    name: 'order-add',
    props: true,
    component: () => import('@/views/orders/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter colis',
      breadcrumb: [
        {
          text: 'colis',
        },
        {
          text: 'Ajouter Colis',
          active: true,
        },
      ],
      roles: ['supplier'],
    },
  },
  {
    path: '/orders/:level',
    name: 'order-list',
    props: true,
    component: () => import('@/views/orders/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des colis',
      breadcrumb: [
        {
          text: 'Colis',
        },
        {
          text: 'Liste des Colis',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
  {
    path: '/orders/edit/:id',
    name: 'order-edit',
    props: true,
    component: () => import('@/views/orders/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier colis',
      breadcrumb: [
        {
          text: 'Colis',
        },
        {
          text: 'Modifier colis',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier'],
    },
  },
  {
    path: '/orders/show/:id',
    name: 'order-show',
    props: true,
    component: () => import('@/views/orders/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Détails colis',
      breadcrumb: [
        {
          text: 'colis',
        },
        {
          text: 'Détails colis',
          active: true,
        },
      ],
      roles: ['admin', 'manager', 'supplier', 'sales'],
    },
  },
  {
    path: '/orders/echange/:id',
    name: 'order-echange-show',
    props: true,
    component: () => import('@/views/orders/ShowEchange.vue'),
    meta: {
      requireLogin: true,
      roles: ['admin', 'manager'],
    },
  },
  // {
  //   path: '/orders/pickup/scan',
  //   name: 'scan-order',
  //   props: true,
  //   component: () => import('@/views/orders/Pickup.vue'),
  //   meta: {
  //     requireLogin: true,
  //     pageTitle: 'Pickup colis',
  //     roles: ['delivery_man'],
  //   },

]
